











































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { EsService } from "@/services/es.service";
import { JnService } from "@/services/jn.service";
import JnProgress from "@/views/common/Progress.vue";
import { Picklist } from "@/models/picklist.model";
import { ValidationObserver } from "vee-validate";
import { SearchData } from "@/models/search.model";
import EventBus from "@/services/event.bus";
import { Utils } from "@/services/utils";
import { CategoriesMassimario, CategoriesJuranet } from "@/models/enums.model";

@Component({
  components: {
    JnProgress,
    ValidationObserver,
  },
})
export default class JnMassimarioSearch extends Vue {
  @Prop({ required: true })
  searchData?: SearchData;

  optText = "";
  optType = "AND";
  optAreeTematiche: Picklist[] = [];
  optMaterie: Picklist[] = [];
  optArgomenti: Picklist[] = [];
  optTipiMassima: Picklist[] = [];
  optTipoOrdinamento = "1";
  optNumRisultati = "20";
  optSearchMassimeUfficiali = false;

  tipiQuery: Picklist[] = [];
  areeTematiche: Picklist[] = [];
  materie: Picklist[] = [];
  argomenti: Picklist[] = [];
  tipiMassima: Picklist[] = [];
  tipiOrdinamento: Picklist[] = [];
  numRisultati: Picklist[] = [];

  areeTematicheRaw!: any;
  tagsRaw!: any;

  alert = false;
  alertMessage = "";
  alertTimeout = 6000;

  advanced = false;
  loading = false;

  async switchAdvanced() {
    this.advanced = !this.advanced;
  }

  async search() {
    if (this.searchData) {
      this.searchData.index = process.env.VUE_APP_JURANEWS_INDEXES!;
      this.searchData.keywords = []
      const optText = this.optText
        ? this.optText.replaceAll('/', '\\/')
        : this.optText;
      if (optText != "") {
        this.searchData.keywords.push(optText)
      }
      this.searchData.queryType = this.optType;
      if (this.optTipoOrdinamento) {
        this.searchData.orderingType = this.optTipoOrdinamento;
      }
      if (this.optNumRisultati) {
        this.searchData.perPage = Number(this.optNumRisultati);
      }

      const idMaterie: string[] = [];
      for (const m of this.optMaterie) {
        idMaterie.push(m.value);
      }
      this.searchData.idMateria = idMaterie;

      const idArgomenti: number[] = [];
      for (const a of this.optArgomenti) {
        idArgomenti.push(Number(a.value));
      }
      this.searchData.idArgomento = idArgomenti;

      const idAreeTematiche: string[] = [];
      for (const t of this.optAreeTematiche) {
        idAreeTematiche.push(t.value);
      }
      this.searchData.idAreaTematica = idAreeTematiche;

      const tipiMassima: string[] = [];
      for (const td of this.optTipiMassima) {
        tipiMassima.push("999999" + td.value);
      }
      if (tipiMassima.length == 0) {
        this.searchData.idCategory = CategoriesMassimario;
      } else {
        this.searchData.idCategory = tipiMassima;
      }

      if (this.optSearchMassimeUfficiali) {
        if (
          !this.searchData.keywords ||
          this.searchData.keywords.length == 0
        ) {
          this.alertMessage =
            'Per ricercare nelle massime della Cassazione è necessario indicare almeno una chiave di ricerca';
          this.alert = true;
          return;
        }
        this.searchData.index = process.env.VUE_APP_JURANET_INDEXES!;
        this.searchData.idCategory = [CategoriesJuranet.DCCASS];
      }
      this.searchData.searchInMassime = this.optSearchMassimeUfficiali;

      const docs = await EsService.getDocs(this.searchData);
      if (docs.length == 0) {
        this.alert = true;
        this.alertMessage = "Nessun risultato trovato";
      }
      EventBus.$emit("search-data", this.searchData);
      EventBus.$emit("search-results", docs);

      this.$store.commit('searchData', this.searchData);
    }
  }

  changeAreaTematica() {
    this.materie = [];
    this.optMaterie = [];
    this.argomenti = [];
    this.optArgomenti = [];
    if (this.searchData) {
      for (const selectedAreaTematica of this.optAreeTematiche) {
        for (const areaTematica of this.areeTematicheRaw["aree-tematiche"]) {
          if (areaTematica.id == selectedAreaTematica.value) {
            for (const materia of areaTematica.materie) {
              this.materie.push(
                new Picklist({ label: materia.name, value: materia.id })
              );
            }
          }
        }
      }
    }
  }

  changeMateria() {
    this.argomenti = [];
    this.optArgomenti = [];
    for (const selectedMateria of this.optMaterie) {
      for (const areaTematica of this.areeTematicheRaw["aree-tematiche"]) {
        for (const materia of areaTematica.materie) {
          if (materia.id == selectedMateria.value) {
            for (const argomento of materia.argomenti) {
              this.argomenti.push(
                new Picklist({ label: argomento.name, value: argomento.id })
              );
            }
          }
        }
      }
    }
  }

  clear() {
    this.optText = "";
    this.optType = "AND";
    this.optTipoOrdinamento = this.tipiOrdinamento[1].value;
    this.optNumRisultati = this.numRisultati[0].value;
    this.optAreeTematiche = [];
    this.optMaterie = [];
    this.optArgomenti = [];
    this.optTipiMassima = [];
  }

  close() {
    this.clear();
    this.advanced = false;
  }

  async created() {
    EventBus.$on("reset-ricerca-area-tematica", () => {
      this.search();
    });

    this.tipiQuery = JnService.getQueryTypes();
    this.tipiOrdinamento = JnService.getTipiOrdinamento();
    this.optTipoOrdinamento = this.tipiOrdinamento[1].value;
    this.numRisultati = JnService.getNumeroRisultati();
    this.optNumRisultati = this.numRisultati[0].value;
    this.areeTematicheRaw = JSON.parse(
      await EsService.getOptions("struttura-aree-tematiche")
    );
    if (this.areeTematicheRaw && this.searchData) {
      for (const areaTematica of this.areeTematicheRaw["aree-tematiche"]) {
        this.areeTematiche.push(
          new Picklist({ label: areaTematica.name, value: areaTematica.id })
        );
        if (
          areaTematica.id ==
          String(this.searchData.idAreaTematica).replace("JN", "")
        ) {
          for (const materia of areaTematica.materie) {
            this.materie.push(
              new Picklist({ label: materia.name, value: materia.id })
            );
          }
        }
      }
    }

    const tipiMassimeRaw = JSON.parse(await EsService.getOptions("massime"));
    for (const tm of tipiMassimeRaw) {
      this.tipiMassima.push(
        new Picklist({ value: tm.id, label: String(tm.value) })
      );
    }
    this.tipiMassima.sort(Utils.sort("label", "asc"));
  }

  resetForMassimeUfficiali() {
    this.optMaterie = [];
    this.optAreeTematiche = [];
    this.optMaterie = [];
    this.optArgomenti = [];
  }
}
